import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { useField } from 'formik';
import { useDispatch } from 'react-redux';
import { setIsEmailVerifiedStatus } from 'redux/actions/auth';

// constants
import { EmailTypes } from '../../../../../enums/onboarding/crm';

// components
import FormField from '@core_components/FormField';
import FormInput from '@common_components/Form/FormInput';
import VerifyEmail from './VerifyEmail';
import DeleteButton from '../../../../DesignSystem/Common/Buttons/DeleteButton';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import EmailTypeSelect from '../../../FormComponents/SelectInputs/CRM/EmailTypeSelect';
import PrimaryStatusText from '../../../../Typography/PrimaryStatusText';
import HideIfDisabledForm from '../../../HideIfDisabledForm';
import VerifiedStatusText from '../../../../Typography/VerifiedStatusText';
import { Col, Row, Tooltip } from 'antd';

interface IProps {
  fieldName: string;
  canVerify?: boolean;
  removeCallback: () => void;
}

export interface FormValuesModel {
  type: EmailTypes | null;
  value: string;
  primary: {
    status: boolean;
    canEdit: boolean;
  };
  isVerified?: boolean;
  disabledEmailAddressFieldTooltipText?: string;
}

const EmailAddressForm = ({ fieldName, canVerify, removeCallback }: IProps) => {
  const { t } = useTranslation(['onboarding', 'profile']);
  const dispatch = useDispatch();
  const [field, , helpers] = useField<FormValuesModel>(fieldName);

  const primary = field.value.primary;
  const gridSizes = { xl: 8, lg: 8, md: 8, sm: 24, xs: 24 };

  const onVerify = () => {
    helpers.setValue({ ...field.value, isVerified: true });
    dispatch(setIsEmailVerifiedStatus(true));
  };

  return (
    <Row gutter={[16, 0]}>
      <Col {...gridSizes}>
        <FormField
          label={t(
            'applicant_information.form_fields.individual.email_address.type',
          )}
          name={`${fieldName}.type`}
          component={EmailTypeSelect}
          disabled={!primary.canEdit}
        />
      </Col>

      <Col flex="1 1">
        <FormField
          label={t(
            'applicant_information.form_fields.individual.email_address.address',
          )}
          name={`${fieldName}.value`}
          component={(props: any) =>
            !primary.canEdit &&
            field.value.disabledEmailAddressFieldTooltipText ? (
              <Tooltip title={field.value.disabledEmailAddressFieldTooltipText}>
                <div>
                  <FormInput {...props} />
                </div>
              </Tooltip>
            ) : (
              <FormInput {...props} />
            )
          }
          disabled={!primary.canEdit}
        />
      </Col>

      <DivAlignCenter>
        <StyledHelperCol>
          {typeof field.value.isVerified === 'boolean' && primary.status && (
            <VerifiedStatusText isVerified={field.value.isVerified} />
          )}
        </StyledHelperCol>

        <StyledHelperCol>
          {primary.status ? (
            <PrimaryStatusText />
          ) : (
            <HideIfDisabledForm hideIfSubmitting>
              <DeleteButton onClick={removeCallback} />
            </HideIfDisabledForm>
          )}
        </StyledHelperCol>
        {canVerify && (
          <StyledHelperCol>
            <VerifyEmail fieldName={fieldName} onVerify={onVerify} />
          </StyledHelperCol>
        )}
      </DivAlignCenter>
    </Row>
  );
};

const StyledHelperCol = styled(Col)`
  display: flex;
  align-items: flex-end;
`;

export default EmailAddressForm;
